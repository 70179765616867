import React, { useState } from 'react'
import PropTypes from 'prop-types'

const defaultToast = {
  show: false,
  message: ''
}

const ToastContext = React.createContext(defaultToast)

export const ToastProvider = ({ children, toast }) => {
  const [currentToast, setToast] = useState(toast || defaultToast)

  const closeToast = () => {
    setToast({ ...currentToast, show: false })
  }

  const openToast = () => {
    setToast({ ...currentToast, show: true })
  }

  const removeToastMessage = () => {
    setToast({ ...currentToast, message: '' })
  }

  const setToastMessage = (message) => {
    setToast({ ...currentToast, message })
  }

  return (
    <ToastContext.Provider
      value={{ toast: currentToast, closeToast, openToast, removeToastMessage, setToast, setToastMessage }}
    >
      {children}
    </ToastContext.Provider>
  )
}

ToastProvider.propTypes = {
  children: PropTypes.any,
  toast: PropTypes.any
}

export default ToastContext
