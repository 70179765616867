import React from 'react'
import { ToastProvider } from './src/context/Toast'

export function wrapPageElement ({ element, props }) {
  const Layout = element.type.Layout ?? React.Fragment
  return (
    <ToastProvider>
      <Layout {...props}>{element}</Layout>
    </ToastProvider>
  )
}
